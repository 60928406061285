import React from "react";

const Copyright = () => {

    return (
        <div className={"copyright"}>
            Copyright Drabek Transport 2020
        </div>
    )
}

export default Copyright;